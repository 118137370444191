import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";

const About = () => {
  const lg = useMediaQuery("(min-width:1200px)");
  const md = useMediaQuery("(min-width:840px)");
  return (
    <a name="about" id="about">
    <Box
      component="section"
      backgroundColor="#e7ebef"
      p="96px 0"
      alignItems={"center"}
      mt={lg ? "608px" : "258px"}
    >
      <Container
        maxWidth="md"
        component="section"
        sx={{
          display: "flex",
          columnGap: "20px",
          rowGap: "20px",
          overflow: "auto",
          justifyContent: "center",
          flexDirection: md ? "row" : "column",
          alignItems: md ? "" : "center",
          marginTop: md ? "" : "20px",
        }}
      >
        <Card sx={{ maxWidth: 345, minWidth: 345 }}>
          <CardMedia
            component={"img"}
            src="https://media.licdn.com/dms/image/D5603AQHejzS6uJYlGw/profile-displayphoto-shrink_800_800/0/1692219671933?e=1700092800&v=beta&t=Twx4RW8RujrpPXdVkRMfGVYhqEZymk0iDRGvhsaM4d8"
            height="290"
          />

          <CardContent alignItems="center" justifyContent="center">
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              justify="center"
              fontWeight="bold"
            >
              Bryan Romero
            </Typography>

            <Typography variant="body2" color="text.secondary" align="center">
              contact@bryanromero.dev
            </Typography>

            <Box display={"flex"} justifyContent={"center"}>
              <IconButton sx={{ "&:hover": { color: "#DB4437" } }}>
                <EmailIcon />
              </IconButton>

              <IconButton
                sx={{ "&:hover": { color: "#0072b1" } }}
                aria-label="LinkedIn Profile"
                onClick={() =>
                  window.open("https://www.linkedin.com/in/bryanromero1")
                }
              >
                <LinkedInIcon />
              </IconButton>

              <IconButton
                sx={{ "&:hover": { color: "#171515 " } }}
                aria-label="Github Profile"
                onClick={() => window.open("https://github.com/bromero1")}
              >
                <GitHubIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>

        <Box>
          <Typography
            variant="h4"
            fontWeight={"bold"}
            align={md ? "" : "center"}
          >
            Who am I?
          </Typography>
          <Typography paragraph fontSize={"20px"}>
            My name is Bryan Romero. I am Software Engineer based in <span style={{ color:"#BD3039", fontWeight: "600"}}>Boston</span>, MA.
            I have experience developing full stack applications using various
            technologies.
          </Typography>

          <Typography paragraph fontSize={"20px"}>
            I began my formal studies as a non-traditional student in community
            college and went on to earn my Bachelors Degree in Computer Science
            from <span style={{ color:"#516FBF", fontWeight: "600"}}>UMass Boston</span>. I have a passion for learning and improving my
            skills as an engineer to create better products and solutions that
            can have an meaningful impact.
          </Typography>
        </Box>
      </Container>
    </Box></a>
  );
};

export default About;
