import { Link, Box, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
const Navbar = () => {
  // const [isScrolled, setScrolled] = useState(false);
  const links = ["about", "resume", "contact"];
  const lg = useMediaQuery("(min-width:750px)");

  return (
    <Box
      component="nav"
      width="100%"
      height="60px"
      backgroundColor="#dee4e8"
      position="sticky"
      top="0"
    >
      <Box
        width="80%"
        height="100%"
        display="flex"
        alignItems="center"
        m="0 auto"
        justifyContent="space-between"
      >
        <Typography
          variant="h5"
          fontWeight="600"
          color="#3f51b5"
          letterSpacing={"3px"}
        >
          {`<Bryan/>`}
        </Typography>

        <Box display={!lg ? "none" : "flex"}>
          {links.map((link) => {
            return (
              <Box p="0 10px">
                <Typography variant="h6" color="#7e57c2" fontWeight={600}>
                  <Link
                    href={`#${link}`}
                    style={{ textDecoration: "none", color: "#7e57c2" }}
                  >
                    {link.toUpperCase()}
                  </Link>
                </Typography>
              </Box>
            );
          })}
          {/* <Typography variant="h4" color="red">
            Bryan Romero
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
