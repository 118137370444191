import {
  Box,
  Typography,
  CardMedia,
  // Container,
  useMediaQuery,
  //  Grid,
} from "@mui/material";

// import avatar from "../assets/avatar.jpg";
import circuitBoard from "../assets/circuit-board.svg";

const Hero = () => {
  const lg = useMediaQuery("(min-width:1200px)");
  const sm = useMediaQuery("(min-width:600px)");

  return (
    <Box
      width="100%"
      height={lg ? "608px" : "264px"}
      // maxWidth={!lg ? "590px"}
      mb={lg ? "608px" : "328px"}
      backgroundColor="#dee4e8"
      display="flex"
      alignItems={"center"}
      position="fixed"
      sx={{
        backgroundImage: `url(${circuitBoard})`,
      }}
    >
      {/* <Container> */}
      <Box
        display="flex"
        flexDirection={!lg ? "column-reverse" : "row"}
        m="0 auto"
        alignItems={"center"}
      >
        {/* flexDirection={!lg ? "column" : "row"}*/}
        {/* LEFT SIDE */}
        <Box
          display="flex"
          flexDirection="column"
          m="0 20px"
          justifyContent={"center"}
        >
          <Typography variant="h4">Hi, I'm Bryan!</Typography>
          <Box width="400px">
            <Typography variant="h6">
              I'm a Software Engineer driven by curiosity and focused on
              building solutions.
            </Typography>
          </Box>
        </Box>

        {/* RIGHT SIDE */}
        <Box
          height={!lg ? "100px" : "inherit"}
          width={!lg ? "100px" : "inherit"}
          mb="0px"
        >
          <CardMedia
            sx={{ borderRadius: "50%" }}
            height="100%"
            component={"img"}
            // src={avatar}
            // borderRadius="50%"
            border="5px solid black"
          />
        </Box>
      </Box>
      {/* </Container> */}
    </Box>
  );
};

export default Hero;
