import { StrikethroughS } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const Skills = () => {
  return (
    <Box backgroundColor="#dee4e8" width="100%" p="60px">
      <Typography align="center" variant="h3" fontWeight="bold">
        Skills
      </Typography>
      <Box
        display="flex"
        width="80%"
        m="40px auto"
        justifyContent="space-between"
        flexWrap={"wrap"}
        columnGap={"15px"}
      >
        <img
          width="124"
          height="124"
          src="https://img.icons8.com/color/144/javascript--v1.png"
          alt="javascript"
        />

        <img
          width="124"
          height="124"
          src="https://img.icons8.com/color/144/html-5--v1.png"
          alt="HTML5"
        />

        <img
          width="124"
          height="124"
          src="https://img.icons8.com/color/144/material-ui.png"
          alt="Material UI"
        />

        <img
          width="124"
          height="124"
          src="https://img.icons8.com/color/144/nodejs.png"
          alt="NodeJs"
        />

        <img
          width="124"
          height="124"
          src="https://img.icons8.com/color/144/postgreesql.png"
          alt="PostgreSQL"
        />

        <img
          width="124"
          height="124"
          src="https://img.icons8.com/plasticine/100/react.png"
          alt="React"
        />
      </Box>
      <Box maxWidth="sm" m="0 auto" align="center">
        <Typography fontSize="20px" paragraph>
          My current work revolves around Javascript and the frameworks built on
          it. I first learned programming using Java and C, then{" "}
          <span style={{ textDecoration: "line-through" }}>
            shot myself in the foot
          </span>
          <span style={{ fontStyle: "italic" }}>{" "}learned</span> Javascript on my
          own.
        </Typography>

        <Typography fontSize="20px" paragraph>
          Like most developers, I have used plenty more tools and languages in
          the past. I don't believe there is a universal best tool for a project and I am always 
          ready to learn something new.
        </Typography>

        <Typography fontSize="30px" fontWeight="bold" fontStyle="italic" paragraph>But!!</Typography>

        <Typography fontSize="20px" paragraph>
          ...I don't think I'll look back and think "Damn, I really wish I learned COBOL..."
        </Typography>

        <Typography fontSize="10px" paragraph>
          sorry not sorry.
        </Typography>

      </Box>
    </Box>
  );
};

export default Skills;
