import { Box } from "@mui/material";
import logo from "./logo.svg";
import "./App.css";
import About from "./components/About";
import Skills from "./components/Skills";

function App() {
  return (
    <Box position="relative" width="100%">
      <Box display="flex" flexDirection="column">
        <About />
        <Skills />
      </Box>
    </Box>
  );
}

export default App;
